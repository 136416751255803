.globe-green-bg {
  background-image: url("../../assets/backgrounds/green-globe-bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 25vh;
  width: 100vw;
}

.terms-card {
  border-radius: 15px;
  background: var(--custom-white);
  box-shadow: 0px 25px 80px -12px rgba(40, 102, 47, 0.1);
  margin-top: -7rem;
}

@media screen and (max-width: 765px) {
  .globe-green-bg {
    height: 20vh;
  }
}
