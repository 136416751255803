/**google input**/
.css-1uccc91-singleValue {
  font-weight: 300 !important;
}
.custom-input {
  border: 1px solid var(--custom-modal-border) !important;
  font-size: 14px;
  border-radius: 4px;
  background: var(--custom-white);
}
.custom-input.currency-input {
  border-left: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.input-disabled {
  background-color: rgba(136, 136, 136, 0.15) !important;
}
.custom-input:focus {
  border: 1px solid var(--custom-primary) !important;
  border-color: var(--custom-primary) !important;
  /* box-shadow: 1px 0.5px 1px 0.5px var(--custom-primary) !important; */
  box-shadow: 0px 0px 0px 4px #dcfce7 !important;
}
.custom-input::placeholder {
  color: var(--custom-grey);
  font-family: "inter";
}
.left-icon {
  position: absolute;
  float: left;
  z-index: 100;
  margin-top: 7px;
  margin-left: 15px;
}
/* 
.password-icon {
  position: absolute;
  float: right;
  margin-left: -30px;
  margin-top: 16px;

  z-index: 2;
} */

/* //phone input */
.PhoneInputInput {
  border: none;
}
.PhoneInputInput--focus,
.PhoneInputInput:focus-visible {
  border: none !important;
  outline: none !important;
}

/* //google places auto complete */
.css-yk16xz-control {
  /* border: 1px solid red !important; */
  border: none !important;
  font-size: 14px;
  color: #374151 !important;
  border-radius: 4px !important;
  /* box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05); */
  background: var(--custom-white);
  font-family: "inter" !important;
  /* padding: 5px 0 !important; */
}
.css-1f43avz-a11yText-A11yText {
  overflow-x: visible !important;
}

/* .css-tj5bde-Svg {
  display: none !important;
} */
/* .css-tlfecz-indicatorContainer{
  display: none !important;
} */

.css-1wa3eu0-placeholder {
  color: var(--custom-grey) !important;
  font-family: "inter";
  font-size: 14px !important;
  /* transform: none !important; */
}

.css-2b097c-container {
  /* margin-left: 10px !important; */
}

/** for react google places drop doen icon*/
.css-tj5bde-Svg{
  fill: #687280 !important;
  stroke-linecap: round !important;
  stroke-linejoin: round !important;
  /* stroke-width: 1.9 !important; */
}

/*for react google places*/
.css-1hwfws3 {
  /* padding: 0.6rem 0.6rem !important; */
  padding: 7px 8px !important;
  font-size: 14px !important;
  max-height: 50px !important;
  color: var(--custom-black);
}
.css-sk39y4-Mt {
  padding: 0.58rem 0.6rem !important;
  font-size: 14px !important;
  max-height: 50px !important;
  color: var(--custom-black);
}
.css-1uccc91-singleValue {
  color: var(--custom-text-black) !important;
}
/* .css-qc6sy-singleValue {
  margin: 0.2rem 0.4rem !important;
} */

.css-1pahdxg-control {
  border: 1px solid var(--custom-primary) !important;
  border-color: var(--custom-primary) !important;
  /* box-shadow: 1px 0.5px 1px 0.5px var(--custom-primary) !important; */
  box-shadow: 0px 0px 0px 4px #dcfce7 !important;
}

/* removing the arrows from number input  */
.custom-input::-webkit-outer-spin-button,
.custom-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.custom-input[type="number"] {
  -moz-appearance: textfield;
}

.error-text {
  color: red;
  font-size: 10px;
}
.customotp-input {
  font-size: 20px !important;
  font-family: "Inter" !important;
  text-align: unset;
  margin-left: unset !important;
}
/* input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
    opacity: 0;
}

input[type="date"]::-webkit-inner-spin-button,    
input[type="date"]::-webkit-clear-button { display: none;
opacity: 0; } */
