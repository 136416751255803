/* .sc-bczRLJ {
  border: 2px dashed var(--custom-primary);
} */

/* kiHXWb  */
.custom-dnd {
  border: 1px solid var(--custom-border-grey);
  background-color: #fafffb;
  border-radius: 5px;
}
