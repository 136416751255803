@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Inter";
  scroll-behavior: smooth;
}

@font-face {
  font-family: "Satoshi";
  src: url(./assets/fonts/OTF/Satoshi-Variable.ttf);
}

iframe#webpack-dev-server-client-overlay{display:none!important}
